import { CheckoutScreen } from "@/enums/CheckoutScreen";
import { ErrorCode } from "@/enums/ErrorCode";
import BvrCheckoutOptions from "@/models/BvrCheckoutOptions";
import router from "@/router";
import * as BvrPayActions from "@/store/constants/BvrPayCheckout/Actions";
import * as BvrPayMutations from "@/store/constants/BvrPayCheckout/Mutations";
import * as CommonActions from "@/store/constants/Common/Actions";
import * as CommonGetters from "@/store/constants/Common/Getters";
import * as CommonMutations from "@/store/constants/Common/Mutations";
import { HttpRequestDispatcher, HttpRequestMethod } from "@/utilities/HttpRequestDispatcher";
import * as SignalR from "@microsoft/signalr";
import { ActionContext, createStore } from "vuex";
import { BvrCheckoutModule } from "./modules/BvrCheckoutModule";

export interface IRootState {
    isWebSocketInitialized: boolean,
    checkoutOptions: BvrCheckoutOptions
}

/* istanbul ignore file */
export default createStore({
    state: {
        isWebSocketInitialized: false,
        checkoutOptions: null as null | BvrCheckoutOptions
    } as IRootState,
    getters: {
        [CommonGetters.COMMON__GET_CHECKOUT_OPTIONS](state: IRootState) {
            return state.checkoutOptions;
        }
    },
    mutations: {
        [CommonMutations.COMMON__SET_CHECKOUT_OPTIONS](state: IRootState, options: BvrCheckoutOptions): void {
            state.checkoutOptions = options;
        }
    },
    actions: {
        async [CommonActions.COMMON__LOAD_CHECKOUT_OPTIONS](context: ActionContext<IRootState, IRootState>): Promise<void> {
            const requestUrl = "/api/Options";

            await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl)
                .then((response: any) => {
                    if (!response || !response.data) {
                        console.error("Checkout options load fail: empty response model");
                        return;
                    }
                    const checkoutOptions: BvrCheckoutOptions = response.data;
                    context.commit(CommonMutations.COMMON__SET_CHECKOUT_OPTIONS, checkoutOptions);
                }).catch(err => {
                    console.error(err);
                });
        },
        [CommonActions.COMMON__INIT_WEB_SOCKET](context: ActionContext<IRootState, IRootState>, chekoutId: string): void {

            const connection = new SignalR.HubConnectionBuilder()
                .withUrl(`/ecomm-pay?checkoutId=${chekoutId}`)
                .withAutomaticReconnect()
                .build();

            connection.on("bvrPaymentConfirmation", (message: ErrorCode) => {
                context.dispatch(BvrPayActions.BVR__STOP_APPROVE_WAIT_TIMER);
                context.commit(BvrPayMutations.BVR__SET_APPROVE_ERROR, null);
                context.commit(BvrPayMutations.BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND, false);

                if (message === ErrorCode.Success) {
                    context.commit(
                        BvrPayMutations.BVR__SET_CURRENT_CHECKOUT_SCREEN,
                        CheckoutScreen.PaymentSuccess
                    );
                } else if (message === ErrorCode.PaymentCancel) {
                    context.commit(
                        BvrPayMutations.BVR__SET_CURRENT_CHECKOUT_SCREEN,
                        CheckoutScreen.PaymentCancel
                    );
                } else {
                    context.commit(BvrPayMutations.BVR__SET_APPROVE_ERROR, message);
                    context.commit(
                        BvrPayMutations.BVR__SET_CURRENT_CHECKOUT_SCREEN,
                        CheckoutScreen.PaymentError
                    );
                }
            });
            connection.on("bvrPayApprove", (message: ErrorCode) => {
                context.commit(BvrPayMutations.BVR__SET_APPROVE_ERROR, message);
            });
            connection.on("bvrPayCriticalError", () => {
                context.dispatch(BvrPayActions.BVR__STOP_APPROVE_WAIT_TIMER);
                router.push("/BvrCheckout/error");
            });
            connection.on("bvrPaymentSuspend", () => {
                context.commit(BvrPayMutations.BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND, true);
            });

            connection.start();
        },
    },
    modules: {
        BvrCheckoutModule
    }
});