export default class PhoneNumberValidator {

    public static validatePhoneNumber(phoneNumber: Number | undefined): boolean {
        if (!phoneNumber) {
            return false;
        }
        //eslint-disable-line
        /* eslint-disable */
        const phoneNumberRegEx = new RegExp(/38\d{10}/);
        const validationResult = phoneNumberRegEx.test(phoneNumber.toString());
        return validationResult;
    }
}