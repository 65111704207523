// Chekout module
export const BVR__LOAD_CHECKOUT_DATA = "BVR__LOAD_CHECKOUT_DATA";
export const BVR__CONFIRM_PAYMENT = "BVR__CONFIRM_PAYMENT";
export const BVR__START_APPROVE_WAIT_TIMER = "BVR__START_APPROVE_WAIT_TIMER";
export const BVR__STOP_APPROVE_WAIT_TIMER = "BVR__STOP_APPROVE_WAIT_TIMER";
export const BVR__START_PUSH_SEND_SUSPENSION_TIMER = "BVR__START_PUSH_SEND_SUSPENSION_TIMER";
export const BVR__STOP_PUSH_SEND_SUSPENSION_TIMER = "BVR__STOP_PUSH_SEND_SUSPENSION_TIMER";
export const BVR__START_REDIRECT_TO_STORE_TIMER = "BVR__START_REDIRECT_TO_STORE_TIMER";
export const BVR__REDIRECT_TO_STORE = "BVR__REDIRECT_TO_STORE";
export const BVR__SHOW_PUSH_CONFIRM_NOTIFICATION = "BVR__SHOW_PUSH_CONFIRM_NOTIFICATION";
export const BVR__LOAD_LOYALTY_POINTS_AMOUNT = "BVR__LOAD_LOYALTY_POINTS_AMOUNT";