export default class TimeCalculator {
    public static addMinutes(date: Date, minutes: number): Date {
        return new Date(date.getTime() + minutes * 60000);
    }

    public static addSeconds(date: Date, seconds: number): Date {
        return new Date(date.getTime() + seconds * 1000);
    }

    public static computePaymentTimeLimitInSeconds(
        lastPaymentDate: Date,
        paymentLimitInMinutes: number,
        paymentLimitInSeconds: number
    ) {
        let paymentTimeoutDate: Date = this.addMinutes(new Date(lastPaymentDate.toString()), paymentLimitInMinutes);
        paymentTimeoutDate = this.addSeconds(new Date(paymentTimeoutDate.toString()), paymentLimitInSeconds);

        const difference =
            paymentTimeoutDate.getTime() - new Date(Date.now()).getTime();
        const differenceInSeconds = Math.round(difference / 1000);

        return differenceInSeconds;
    }

    public static getPaymentTimeLimitAsString(
        lastPaymentDate: Date,
        paymentLimitInMinutes: number,
        paymentLimitInSeconds: number
    ): string {
        const differenceInSeconds = this.computePaymentTimeLimitInSeconds(
            lastPaymentDate,
            paymentLimitInMinutes,
            paymentLimitInSeconds
        );
        const minutes = Math.floor(differenceInSeconds / 60);
        const minutesAsString = minutes.toString().padStart(2, "0");
        const seconds = Math.round(differenceInSeconds - minutes * 60);
        const secondsAsString = seconds.toString().padStart(2, "0");

        return `${minutesAsString}:${secondsAsString}`;
    }
}