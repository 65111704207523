export default class UrlRedirectResolver {
    public static windowMock?: any;

    /* istanbul ignore next */
    public static setupMock(window: any) {
        this.windowMock = window;
    }
    public static redirectToUrl = (url: string) => {
        let windowObject = window;

        /* istanbul ignore next */
        if(this.windowMock) {
            windowObject = this.windowMock;
        }
        windowObject.location.href = url;
    }
}