import { VUE_APP_TEST_ENDPOINT } from "@/main";
import axios, { AxiosRequestConfig } from "axios";

export enum HttpRequestMethod {
  GET = 1,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export class HttpConfig implements AxiosRequestConfig {
  constructor(data?: HttpConfig) {
    Object.assign(this, data);
  }
}

export class HttpRequestDispatcher {
  private static httpClient: any;

  public static SendRequest<T, K>(
    method: HttpRequestMethod,
    url: string,
    data?: T,
    customHttpClient?: any
  ): Promise<K> {
    /* istanbul ignore next */
    customHttpClient
      ? (this.httpClient = customHttpClient)
      : (this.httpClient = axios);

    // Only for local development
    const baseUrl = VUE_APP_TEST_ENDPOINT;

    let fetchUrl = url;

    if(baseUrl) {
      fetchUrl = baseUrl + url;
    }

    switch (method) {
      case HttpRequestMethod.GET: {
        return this.Get(fetchUrl, this.httpClient);
      }
      case HttpRequestMethod.POST: {
        return this.Post(fetchUrl, data, this.httpClient);
      }
      case HttpRequestMethod.PUT: {
        return this.Put(fetchUrl, data, this.httpClient);
      }
      case HttpRequestMethod.DELETE: {
        return this.Delete(fetchUrl, this.httpClient);
      }
      case HttpRequestMethod.PATCH: {
        return this.Patch(fetchUrl, data, this.httpClient);
      }
    }
  }
  public static async Get<T, K>(url: string, httpClient: any): Promise<K> {
    const config = new HttpConfig({
      url: url,
      method: "get"
    });
    let response;
    try {
      response = await httpClient.get(url, config);
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }

    return response;
  }

  public static async Post<T, K>(url: string, data: T, httpClient: any): Promise<K> {
    let response;
    try {
      response = await httpClient.post(url, data);
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }
    return response;
  }

  // Unused method
  /* istanbul ignore next */
  public static async Put<T, K>(url: string, data: T, httpClient: any): Promise<K> {
    let response;
    try {
      response = await httpClient.put(url, data);
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }
    return response;
  }

  // Unused method
  /* istanbul ignore next */
  public static async Delete<T, K>(
    url: string,
    httpClient: any
  ): Promise<K> {
    let response;
    try {
      response = await httpClient.delete(url);
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }
    return response;
  }

  // Unused method
  /* istanbul ignore next */
  public static async Patch<T, K>(url: string, data: T, httpClient: any): Promise<K> {
    let response;
    try {
      response = await httpClient.patch(url, data);
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }
    return response;
  }
}
