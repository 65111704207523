import "@quasar/extras/material-icons/material-icons.css";
import "./styles/quasar.scss";

// To be used on app.use(Quasar, { ... })
export default {
  build: {
    minify: true,
    transpile: true,
    showProgress: true
  },
  framework: {
    // ...
    autoImportComponentCase: 'kebab',
  },
  config: {
  },
  plugins: {},
};
