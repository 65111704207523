
import Header from "@/components/Header/Header.vue";
import Platform from 'quasar/src/plugins/platform/Platform.js';;
import { defineComponent, onUpdated, ref, Ref } from "vue";

export default defineComponent({
  name: "LandscapeOrientationWarning",
  components: {
    Header,
  },
  props: {
    minWidth: String,
    minHeight: String,
  },
  setup() {
    let platformObject = Platform;
    let windowObject = window;

    const isDeviceRotationMessageVisible: Ref<boolean> = ref(false);

    /* istanbul ignore next */
    const setupMocks = (windowMock: any, platformMock: any) => {
      windowObject = windowMock;
      platformObject = platformMock
    }

    onUpdated(() => {
      handleComponentUpdate();
    });

    const handleComponentUpdate = () => {
      const isMobile: boolean = !platformObject.is.desktop;
      const isPortrait: boolean = windowObject.matchMedia("(orientation: portrait)").matches === true;

      isDeviceRotationMessageVisible.value = isMobile && !isPortrait;
    }

    return {
      setupMocks,
      handleComponentUpdate, // Just for unit-testing
      isDeviceRotationMessageVisible,
    };
  },
});
