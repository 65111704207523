// Checkout module
export const BVR__SET_ERROR_CODE = "BVR__SET_ERROR_CODE";
export const BVR__SET_IS_CHECKOUT_WAITING = "BVR__SET_IS_CHECKOUT_WAITING";
export const BVR__SET_CHECKOUT_DATA = "BVR__SET_CHECKOUT_DATA";
export const BVR__SET_CURRENT_CHECKOUT_SCREEN = "BVR__SET_CURRENT_CHECKOUT_SCREEN";
export const BVR__SET_PAYMENT_BONUS_AMOUNT = "BVR__SET_PAYMENT_BONUS_AMOUNT";
export const BVR__SET_PAYMENT_AUTHORIZATION_START_DATETIME = "BVR__SET_PAYMENT_AUTHORIZATION_START_DATETIME";
export const BVR__SET_PAYMENT_TIME_LIMIT_IN_SECONDS = "BVR__SET_PAYMENT_TIME_LIMIT_IN_SECONDS";
export const BVR__SET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT = "BVR__SET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT";
export const BVR__SET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT = "BVR__SET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT";
export const BVR__SET_PAYMENT_TIMER_VALUE = "BVR__SET_PAYMENT_TIMER_VALUE";
export const BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE = "BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE";
export const BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR = "BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR";
export const BVR__SET_CHECKOUT_APPROVE_TIMER = "BVR__SET_CHECKOUT_APPROVE_TIMER";
export const BVR__SET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER = "BVR__SET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER";
export const BVR__SET_BONUS_MULTIPLICATION_FACTOR = "BVR__SET_BONUS_MULTIPLICATION_FACTOR";
export const BVR__SET_LAST_PUSH_SEND_ATTEMPT_DATE = "BVR__SET_LAST_PUSH_SEND_ATTEMPT_DATE";
export const BVR__SET_PUSH_SEND_SUSPENSION_IN_SECONDS = "BVR__SET_PUSH_SEND_SUSPENSION_IN_SECONDS";
export const BVR__SET_PUSH_SEND_SUSPENSION_TIME_LEFT = "BVR__SET_PUSH_SEND_SUSPENSION_TIME_LEFT";
export const BVR__SET_PUSH_SEND_SUSPENSION_TIMER = "BVR__SET_PUSH_SEND_SUSPENSION_TIMER";
export const BVR__SET_APPROVE_BUTTON_LOCK_STATE = "BVR__SET_APPROVE_BUTTON_LOCK_STATE";
export const BVR__SET_APPROVE_ERROR = "BVR__SET_APPROVE_ERROR";
export const BVR__SET_REDIRECT_TIMEOUT_IN_SECONDS = "BVR__SET_REDIRECT_TIMEOUT_IN_SECONDS";
export const BVR__SET_IS_PUSH_CONFIRM_REMINDER_VISIBLE = "BVR__SET_IS_PUSH_CONFIRM_REMINDER_VISIBLE";
export const BVR__SET_MERCHANT_INFO = "BVR__SET_MERCHANT_INFO";
export const BVR__SET_LOYALTY_POINTS_AMOUNT = "BVR__SET_LOYALTY_POINTS_AMOUNT";
export const BVR__SET_LOYALTY_POINTS_OFFER = "BVR__SET_LOYALTY_POINTS_OFFER";
export const BVR__SET_IS_LOYALTY_POINTS_LOAD_WAITING = "BVR__SET_IS_LOYALTY_POINTS_LOAD_WAITING";
export const BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND = "BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND";
export const BVR__SET_IS_ORDER_NUMBER_HIDDEN = "BVR__SET_IS_ORDER_NUMBER_HIDDEN";