import { PaymentMethod } from "@/enums/PaymentMethod";

export default class LoyaltyPointsRequest {
    public phoneNumber?: Number;
    public merchantId?: Number;
    public amount?: Number;
    public cultureName?: String;
    public paymentMethod?: PaymentMethod;

    constructor(instance?: LoyaltyPointsRequest) {
        Object.assign(this, instance);
    }
}