import { CheckoutScreen } from "@/enums/CheckoutScreen";
import { ErrorCode } from "@/enums/ErrorCode";
import { HttpStatus } from "@/enums/HttpStatusCode";
import { PaymentMethod } from "@/enums/PaymentMethod";
import { BvrCheckoutData } from "@/models/BvrCheckoutData";
import ApplicationCache from "@/models/cache/ApplicationCache";
import BvrCheckoutCache from "@/models/cache/CheckoutCache";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import LoyaltyPointsRequest from "@/models/requests/LoyaltyPointsRequest";
import ApproveSendResult from "@/models/responses/ApproveSendResult";
import LoyaltyPointsResponse from "@/models/responses/LoyaltyPointsResponse";
import router from "@/router";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as Mutations from "@/store/constants/BvrPayCheckout/Mutations";
import { HttpRequestDispatcher, HttpRequestMethod } from "@/utilities/HttpRequestDispatcher";
import PhoneNumberValidator from "@/utilities/PhoneNumberValidator";
import TimeCalculator from "@/utilities/TimeCalculator";
import UrlRedirectResolver from "@/utilities/UrlRedirectResolver";
import { ActionContext } from "vuex";
import { IRootState } from "..";

export interface IBvrCheckoutState {
    checkoutData: BvrCheckoutData;
    merchantInfo: MerchantInfoCache;
    isWaiting: Boolean;
    currentCheckoutScreen: CheckoutScreen;
    paymentAuthorizationAttemptsLeft: number | null,
    paymentAuthorizationAttemptsLimit: number | null,
    paymentTimeLimitMinutes: number | null;
    paymentAuthorizationStartDateTime: Date | null;
    paymentCounterValue: string;
    clientPhoneInputErrorMessage: string,
    isClientPhoneInputError: boolean,
    checkoutApproveTimer: any,
    pushSendSuspensionTimer: any,
    pushSendSuspensionInSeconds: number | null,
    pushSendSuspensionTimeLeft: number | null,
    redirectTimeoutInSeconds: number | null,
    canChangeEditClientPhoneNumber: boolean,
    bonusMultiplicationFactor: number,
    pushSendLastAttemptDate: Date | null,
    isApproveButtonLock: boolean,
    approveError: number | null,
    isPushConfirmReminderVisible: boolean,
    loyaltyPointsAmount: number | null,
    loyaltyPointsOffer: string | null,
    isLoyaltyPointsLoadWaiting: boolean,
    isCheckoutPaymentSuspend: boolean,
    isOrderNumberHidden: boolean,
}

export const BvrCheckoutModule = {
    state: {
        checkoutData: new BvrCheckoutData(),
        merchantInfo: new MerchantInfoCache(),
        isWaiting: false,
        checkoutErrorCode: null,
        currentCheckoutScreen: 1,
        paymentAuthorizationAttemptsLeft: null,
        paymentAuthorizationAttemptsLimit: null,
        paymentTimeLimitMinutes: null,
        paymentAuthorizationStartDateTime: null,
        paymentCounterValue: "--:--",
        clientPhoneInputErrorMessage: "",
        isClientPhoneInputError: false,
        checkoutApproveTimer: null,
        canChangeEditClientPhoneNumber: true,
        bonusMultiplicationFactor: 5,
        pushSendSuspensionInSeconds: 60,
        pushSendSuspensionTimeLeft: 0,
        pushSendSuspensionTimer: null,
        pushSendLastAttemptDate: null,
        redirectTimeoutInSeconds: null,
        isApproveButtonLock: true,
        approveError: null,
        isPushConfirmReminderVisible: false,
        loyaltyPointsAmount: null,
        loyaltyPointsOffer: null,
        isLoyaltyPointsLoadWaiting: false,
        isCheckoutPaymentSuspend: false,
        isOrderNumberHidden: false
    } as IBvrCheckoutState,
    getters: {
        [Getters.BVR__GET_IS_ORDER_NUMBER_HIDDEN]: (state: IBvrCheckoutState): boolean => state.isOrderNumberHidden,
        [Getters.BVR__GET_CHECKOUT_DATA]: (state: IBvrCheckoutState): BvrCheckoutData => state.checkoutData,
        [Getters.BVR__GET_IS_CHECKOUT_WAITING]: (state: IBvrCheckoutState): Boolean => state.isWaiting,
        [Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN]: (state: IBvrCheckoutState): Number => state.currentCheckoutScreen,
        [Getters.BVR__GET_PAYMENT_AUTHORIZATION_START_DATETIME]: (state: IBvrCheckoutState): Date | null => state.paymentAuthorizationStartDateTime,
        [Getters.BVR__GET_PAYMENT_TIME_LIMIT_IN_SECONDS]: (state: IBvrCheckoutState): Number | null => state.paymentTimeLimitMinutes,
        [Getters.BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT]: (state: IBvrCheckoutState): Number | null => state.paymentAuthorizationAttemptsLeft,
        [Getters.BVR__GET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT]: (state: IBvrCheckoutState): Number | null => state.paymentAuthorizationAttemptsLimit,
        [Getters.BVR__GET_PAYMENT_TIMER_VALUE]: (state: IBvrCheckoutState): String => state.paymentCounterValue,
        [Getters.BVR__GET_CLIENT_PHONE_INPUT_ERROR_MESSAGE]: (state: IBvrCheckoutState): String => state.clientPhoneInputErrorMessage,
        [Getters.BVR__GET_IS_CLIENT_PHONE_INPUT_ERROR]: (state: IBvrCheckoutState): Boolean => state.isClientPhoneInputError,
        [Getters.BVR__GET_CHECKOUT_APPROVE_TIMER]: (state: IBvrCheckoutState): any => state.checkoutApproveTimer,
        [Getters.BVR__GET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER]: (state: IBvrCheckoutState): Boolean => state.canChangeEditClientPhoneNumber,
        [Getters.BVR__GET_PUSH_SEND_SUSPENSION_IN_SECONDS]: (state: IBvrCheckoutState): Number | null => state.pushSendSuspensionInSeconds,
        [Getters.BVR__GET_PUSH_SEND_SUSPENSION_TIME_LEFT]: (state: IBvrCheckoutState): Number | null => state.pushSendSuspensionTimeLeft,
        [Getters.BVR__GET_PUSH_SEND_SUSPENSION_TIMER]: (state: IBvrCheckoutState): any => state.pushSendSuspensionTimer,
        [Getters.BVR__GET_LAST_PUSH_SEND_ATTEMPT_DATE]: (state: IBvrCheckoutState): any => state.pushSendLastAttemptDate,
        [Getters.BVR__GET_APPROVE_BUTTON_LOCK_STATE]: (state: IBvrCheckoutState): boolean => state.isApproveButtonLock,
        [Getters.BVR__GET_APPROVE_ERROR]: (state: IBvrCheckoutState): number | null => state.approveError,
        [Getters.BVR__GET_REDIRECT_TIMEOUT_IN_SECONDS]: (state: IBvrCheckoutState): any => state.redirectTimeoutInSeconds,
        [Getters.BVR__GET_IS_PUSH_CONFIRM_REMINDER_VISIBLE]: (state: IBvrCheckoutState): boolean => state.isPushConfirmReminderVisible,
        [Getters.BVR__GET_MERCHANT_INFO]: (state: IBvrCheckoutState): MerchantInfoCache => state.merchantInfo,
        [Getters.BVR__GET_LOYALTY_POINTS_AMOUNT]: (state: IBvrCheckoutState): number | null => state.loyaltyPointsAmount,
        [Getters.BVR__GET_IS_LOYALTY_POINTS_LOAD_WAITING]: (state: IBvrCheckoutState): boolean => state.isLoyaltyPointsLoadWaiting,
        [Getters.BVR__GET_IS_CHECKOUT_PAYMENT_SUSPEND]: (state: IBvrCheckoutState): boolean => state.isCheckoutPaymentSuspend,
        [Getters.BVR__GET_LOYALTY_POINTS_OFFER]: (state: IBvrCheckoutState): string | null => state.loyaltyPointsOffer
    },
    mutations: {
        [Mutations.BVR__SET_IS_ORDER_NUMBER_HIDDEN](state: IBvrCheckoutState, isHidden: boolean): void {
            state.isOrderNumberHidden = isHidden;
        },
        [Mutations.BVR__SET_CHECKOUT_DATA](state: IBvrCheckoutState, checkoutData: BvrCheckoutData): void {
            state.checkoutData = checkoutData;
        },
        [Mutations.BVR__SET_IS_CHECKOUT_WAITING](state: IBvrCheckoutState, isWaiting: boolean): void {
            state.isWaiting = isWaiting;
        },
        [Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN](state: IBvrCheckoutState, currentCheckoutScreen: number): void {
            state.currentCheckoutScreen = currentCheckoutScreen;
        },
        [Mutations.BVR__SET_PAYMENT_AUTHORIZATION_START_DATETIME](state: IBvrCheckoutState, paymentAuthorizationStartDateTime: Date): void {
            state.paymentAuthorizationStartDateTime = paymentAuthorizationStartDateTime;
        },
        [Mutations.BVR__SET_PAYMENT_TIME_LIMIT_IN_SECONDS](state: IBvrCheckoutState, paymentTimeLimitMinutes: number): void {
            state.paymentTimeLimitMinutes = paymentTimeLimitMinutes;
        },
        [Mutations.BVR__SET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT](state: IBvrCheckoutState, paymentAuthorizationAttemptsLeft: number): void {
            state.paymentAuthorizationAttemptsLeft = paymentAuthorizationAttemptsLeft;
        },
        [Mutations.BVR__SET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT](state: IBvrCheckoutState, paymentAuthorizationAttemptsLimit: number): void {
            state.paymentAuthorizationAttemptsLimit = paymentAuthorizationAttemptsLimit;
        },
        [Mutations.BVR__SET_PAYMENT_TIMER_VALUE](state: IBvrCheckoutState, paymentCounterValue: string): void {
            state.paymentCounterValue = paymentCounterValue;
        },
        [Mutations.BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE](state: IBvrCheckoutState, clientPhoneInputErrorMessage: string): void {
            state.clientPhoneInputErrorMessage = clientPhoneInputErrorMessage;
        },
        [Mutations.BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR](state: IBvrCheckoutState, isClientPhoneInputError: boolean): void {
            state.isClientPhoneInputError = isClientPhoneInputError;
        },
        [Mutations.BVR__SET_CHECKOUT_APPROVE_TIMER](state: IBvrCheckoutState, checkoutApproveTimer: any): void {
            state.checkoutApproveTimer = checkoutApproveTimer;
        },
        [Mutations.BVR__SET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER](state: IBvrCheckoutState, canChangeEditClientPhoneNumber: boolean): void {
            state.canChangeEditClientPhoneNumber = canChangeEditClientPhoneNumber
        },
        [Mutations.BVR__SET_PUSH_SEND_SUSPENSION_IN_SECONDS](state: IBvrCheckoutState, pushSendSuspensionInSeconds: number): void {
            state.pushSendSuspensionInSeconds = pushSendSuspensionInSeconds
        },
        [Mutations.BVR__SET_PUSH_SEND_SUSPENSION_TIME_LEFT](state: IBvrCheckoutState, pushSendSuspensionTimeLeft: number): void {
            state.pushSendSuspensionTimeLeft = pushSendSuspensionTimeLeft
        },
        [Mutations.BVR__SET_PUSH_SEND_SUSPENSION_TIMER](state: IBvrCheckoutState, pushSendSuspensionTimer: number): void {
            state.pushSendSuspensionTimer = pushSendSuspensionTimer
        },
        [Mutations.BVR__SET_LAST_PUSH_SEND_ATTEMPT_DATE](state: IBvrCheckoutState, pushSendLastAttemptDate: Date | null): void {
            state.pushSendLastAttemptDate = pushSendLastAttemptDate
        },
        [Mutations.BVR__SET_APPROVE_BUTTON_LOCK_STATE](state: IBvrCheckoutState, isApproveButtonLock: boolean): void {
            state.isApproveButtonLock = isApproveButtonLock;
        },
        [Mutations.BVR__SET_APPROVE_ERROR](state: IBvrCheckoutState, approveError: number): void {
            state.approveError = approveError;
        },
        [Mutations.BVR__SET_REDIRECT_TIMEOUT_IN_SECONDS](state: IBvrCheckoutState, redirectTimeoutInSeconds: number): void {
            state.redirectTimeoutInSeconds = redirectTimeoutInSeconds;
        },
        [Mutations.BVR__SET_IS_PUSH_CONFIRM_REMINDER_VISIBLE](state: IBvrCheckoutState, isPushConfirmReminderVisible: boolean): void {
            state.isPushConfirmReminderVisible = isPushConfirmReminderVisible
        },
        [Mutations.BVR__SET_MERCHANT_INFO](state: IBvrCheckoutState, merchantInfo: MerchantInfoCache): void {
            state.merchantInfo = merchantInfo;
        },
        [Mutations.BVR__SET_LOYALTY_POINTS_AMOUNT](state: IBvrCheckoutState, loyaltyPointsAmount: number): void {
            state.loyaltyPointsAmount = loyaltyPointsAmount
        },
        [Mutations.BVR__SET_IS_LOYALTY_POINTS_LOAD_WAITING](state: IBvrCheckoutState, isLoyaltyPointsLoadWaiting: boolean): void {
            state.isLoyaltyPointsLoadWaiting = isLoyaltyPointsLoadWaiting
        },
        [Mutations.BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND](state: IBvrCheckoutState, isCheckoutPaymentSuspend: boolean): void {
            state.isCheckoutPaymentSuspend = isCheckoutPaymentSuspend;
        },
        [Mutations.BVR__SET_LOYALTY_POINTS_OFFER](state: IBvrCheckoutState, loyaltyPointsOffer: string): void {
            state.loyaltyPointsOffer = loyaltyPointsOffer;
        },
    },
    actions: {
        [Actions.BVR__SHOW_PUSH_CONFIRM_NOTIFICATION](context: ActionContext<IBvrCheckoutState, IRootState>, durationMs: number): void {
            setTimeout(() => {
                context.commit(Mutations.BVR__SET_IS_PUSH_CONFIRM_REMINDER_VISIBLE, true);
            }, 1000);
            setTimeout(() => {
                context.commit(Mutations.BVR__SET_IS_PUSH_CONFIRM_REMINDER_VISIBLE, false);
            }, durationMs);
        },
        async [Actions.BVR__LOAD_LOYALTY_POINTS_AMOUNT](context: ActionContext<IBvrCheckoutState, IRootState>): Promise<void> {
            context.commit(Mutations.BVR__SET_IS_LOYALTY_POINTS_LOAD_WAITING, true);
            const data: BvrCheckoutData = context.getters[Getters.BVR__GET_CHECKOUT_DATA];

            const loyaltyPointsRequest = new LoyaltyPointsRequest({
                phoneNumber: data.phoneNumber,
                merchantId: data.merchantId,
                amount: data.amount,
                cultureName: data.cultureName,
                paymentMethod: PaymentMethod.BvrPay
            });

            await HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, `/api/LoyalityPoints`, loyaltyPointsRequest)
                .then((response: any) => {
                    if (!response || !response.data || response.status !== 200) {
                        /* istanbul ignore next */
                        console.error("LOAD_LOYALTY_POINTS_AMOUNT: Response or response data is null. Response received: ", response);
                    } else {
                        const data: LoyaltyPointsResponse = response.data.response;
                        context.commit(Mutations.BVR__SET_LOYALTY_POINTS_AMOUNT, data.loyaltyPointsAmount);
                        context.commit(Mutations.BVR__SET_LOYALTY_POINTS_OFFER, data.loyaltyPointsOffer);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => context.commit(Mutations.BVR__SET_IS_LOYALTY_POINTS_LOAD_WAITING, false));
        },
        async [Actions.BVR__LOAD_CHECKOUT_DATA](context: ActionContext<IBvrCheckoutState, IRootState>, checkoutId: string): Promise<void> {
            context.commit(Mutations.BVR__SET_IS_CHECKOUT_WAITING, true);
            const requestUrl = `/api/BvrCheckout?checkoutId=${checkoutId}`;

            await HttpRequestDispatcher.SendRequest(HttpRequestMethod.GET, requestUrl)
                .then((response: any) => {
                    if (!response || !response.data) {
                        router.push('/');
                        return;
                    }

                    const applicationCache: ApplicationCache = response.data;
                    const checkoutCache: BvrCheckoutCache = applicationCache.bvrCheckoutCache!;
                    const merchantInfoCache: MerchantInfoCache = applicationCache.merchantInfoCache!;

                    if (!PhoneNumberValidator.validatePhoneNumber(checkoutCache.bvrCheckoutData?.phoneNumber!)) {
                        context.commit(Mutations.BVR__SET_APPROVE_ERROR, ErrorCode.InvalidPhoneNumber);
                        context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentError);
                        return;
                    }

                    context.commit(Mutations.BVR__SET_CHECKOUT_DATA, checkoutCache.bvrCheckoutData);

                    if (checkoutCache.isPayedOrder) {
                        context.commit(Mutations.BVR__SET_APPROVE_ERROR, ErrorCode.IsPayedOrder);
                        context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentError);
                        return;
                    }

                    context.commit(Mutations.BVR__SET_PAYMENT_TIME_LIMIT_IN_SECONDS, merchantInfoCache.maxApproveTimeSeconds);
                    context.commit(Mutations.BVR__SET_REDIRECT_TIMEOUT_IN_SECONDS, merchantInfoCache.successAutoRedirectTimeSeconds);
                    context.commit(Mutations.BVR__SET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT, merchantInfoCache.maxApproveAttempts);
                    context.commit(Mutations.BVR__SET_PUSH_SEND_SUSPENSION_IN_SECONDS, merchantInfoCache.bvrPaySendPushRetryTimeSeconds);
                    context.commit(Mutations.BVR__SET_IS_ORDER_NUMBER_HIDDEN, merchantInfoCache.isHiddenPartnerOrderId);

                    context.commit(Mutations.BVR__SET_PAYMENT_AUTHORIZATION_START_DATETIME, checkoutCache.paymentAuthorizationInitDate);
                    context.commit(Mutations.BVR__SET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT, checkoutCache.paymentConfirmationAttemptsLeft);
                    context.commit(Mutations.BVR__SET_LAST_PUSH_SEND_ATTEMPT_DATE, checkoutCache.pushSendLastAttemptDate);
                    context.commit(Mutations.BVR__SET_MERCHANT_INFO, merchantInfoCache);
                    context.commit(Mutations.BVR__SET_IS_CHECKOUT_PAYMENT_SUSPEND, checkoutCache.isPaymentProcessWaiting);

                    if (checkoutCache.paymentConfirmationAttemptsLeft === 0) {
                        context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentAttemptsEnded);
                        return;
                    }

                    if (checkoutCache && checkoutCache.isCanceledPayment) {
                        context.commit(Mutations.BVR__SET_APPROVE_ERROR, ErrorCode.PaymentCancel);
                        context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentError);
                        return;
                    }

                    context.dispatch(Actions.BVR__CONFIRM_PAYMENT)
                        .then(() => context.dispatch(Actions.BVR__LOAD_LOYALTY_POINTS_AMOUNT));
                })
                .catch((error: any) => {
                    if (error.response.status === 404) {
                        router.push('/');

                    } else {
                        console.log(error);
                        router.push('/BvrCheckout/error');
                    }
                    /* istanbul ignore next */
                    process.env.JEST_WORKER_ID || console.error(error);
                })
                .finally(() => {
                    context.commit(Mutations.BVR__SET_IS_CHECKOUT_WAITING, false);
                })
        },
        [Actions.BVR__CONFIRM_PAYMENT](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            context.commit(Mutations.BVR__SET_IS_CHECKOUT_WAITING, true);
            context.commit(Mutations.BVR__SET_APPROVE_BUTTON_LOCK_STATE, true);

            const currentDateInUtc = new Date(new Date(Date.now()).toUTCString());
            const requestUrl = `/api/BvrCheckoutApprove`;
            const data: BvrCheckoutData = context.getters[Getters.BVR__GET_CHECKOUT_DATA];
            const availableAuthorizationAttempts = context.getters[Getters.BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT];
            const lastPaymentDate: Date = new Date(context.getters[Getters.BVR__GET_PAYMENT_AUTHORIZATION_START_DATETIME]);
            const paymentTimeoutInSeconds: number = context.getters[Getters.BVR__GET_PAYMENT_TIME_LIMIT_IN_SECONDS];
            const paymentTimeoutDate = TimeCalculator.addSeconds(lastPaymentDate, paymentTimeoutInSeconds);
            const currentCheckoutScreen = context.getters[Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN];

            if (availableAuthorizationAttempts === 0) {
                context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentAttemptsEnded);
                context.commit(Mutations.BVR__SET_IS_CHECKOUT_WAITING, false);
                return;
            }

            if (paymentTimeoutDate < currentDateInUtc && currentCheckoutScreen !== CheckoutScreen.PaymentFail) {
                context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentFail);
                return;
            }

            HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestUrl, data)
                .then((response: any) => {
                    if (!response || !response.data || response.status !== 200) {
                        console.error("Approve response or response data is null. Response received: ", response);
                        router.push('/BvrCheckout/error');
                        return;
                    }

                    const approveSendResult: ApproveSendResult = response.data;
                    context.commit(Mutations.BVR__SET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT, approveSendResult.bvrCheckoutCache!.paymentConfirmationAttemptsLeft);
                    context.commit(Mutations.BVR__SET_PAYMENT_AUTHORIZATION_START_DATETIME, approveSendResult.bvrCheckoutCache!.paymentAuthorizationInitDate);
                    context.commit(Mutations.BVR__SET_LAST_PUSH_SEND_ATTEMPT_DATE, approveSendResult.bvrCheckoutCache!.pushSendLastAttemptDate);

                    if (approveSendResult.errorCode && approveSendResult.errorCode != ErrorCode.Success) {
                        context.commit(Mutations.BVR__SET_APPROVE_ERROR, approveSendResult.errorCode);
                        context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentError);
                        return;
                    }

                    context.dispatch(Actions.BVR__START_APPROVE_WAIT_TIMER);
                    context.dispatch(Actions.BVR__START_PUSH_SEND_SUSPENSION_TIMER);

                })
                .catch((error: any) => {
                    /* istanbul ignore next */
                    process.env.JEST_WORKER_ID || console.error(error);
                    context.commit(Mutations.BVR__SET_APPROVE_BUTTON_LOCK_STATE, false);
                    context.commit(Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN, CheckoutScreen.PaymentFail);
                    if (error.response.status === HttpStatus.NotFound) {
                        router.push('/');
                    } else {
                        console.log(error);
                        router.push('/BvrCheckout/error');
                    }
                })
                .finally(() => {
                    context.commit(Mutations.BVR__SET_IS_CHECKOUT_WAITING, false);
                })
        },
        [Actions.BVR__START_APPROVE_WAIT_TIMER](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            const lastPaymentAttemptDate = context.getters[Getters.BVR__GET_PAYMENT_AUTHORIZATION_START_DATETIME];
            const paymentTimeoutLimitInSeconds = context.getters[Getters.BVR__GET_PAYMENT_TIME_LIMIT_IN_SECONDS];
            const previousTimer = context.getters[Getters.BVR__GET_CHECKOUT_APPROVE_TIMER];

            if (previousTimer) {
                clearInterval(previousTimer);
            }

            const timer = setInterval(() => {
                if (
                    TimeCalculator.computePaymentTimeLimitInSeconds(
                        lastPaymentAttemptDate,
                        0,
                        paymentTimeoutLimitInSeconds,
                    ) <= 0
                ) {
                    context.dispatch(Actions.BVR__STOP_APPROVE_WAIT_TIMER);
                    context.commit(
                        Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN,
                        CheckoutScreen.PaymentFail
                    );
                }
                context.commit(
                    Mutations.BVR__SET_PAYMENT_TIMER_VALUE,
                    TimeCalculator.getPaymentTimeLimitAsString(
                        lastPaymentAttemptDate,
                        0,
                        paymentTimeoutLimitInSeconds,
                    )
                );
            }, 1000);
            context.commit(Mutations.BVR__SET_CHECKOUT_APPROVE_TIMER, timer);
        },
        [Actions.BVR__STOP_APPROVE_WAIT_TIMER](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            const timer = context.getters[Getters.BVR__GET_CHECKOUT_APPROVE_TIMER];
            clearInterval(timer);
        },
        [Actions.BVR__REDIRECT_TO_STORE](context: ActionContext<IBvrCheckoutState, IRootState>, timer: any) {
            const errorcode = context.getters[Getters.BVR__GET_APPROVE_ERROR];
            const checkoutData = context.getters[Getters.BVR__GET_CHECKOUT_DATA];
            const merchantInfo: MerchantInfoCache = context.getters[Getters.BVR__GET_MERCHANT_INFO];
            const currentScreen: CheckoutScreen = context.getters[Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN];

            const failureRedirectUrl = checkoutData.failureRedirectUrl
                ? checkoutData.failureRedirectUrl
                : merchantInfo.defaultFailureRedirectUrl;

            const successRedirectUrl = checkoutData.successRedirectUrl
                ? checkoutData.successRedirectUrl
                : merchantInfo.defaultSuccessRedirectUrl;

            if (errorcode && errorcode !== ErrorCode.Success || currentScreen === CheckoutScreen.PaymentConfirmation) {
                UrlRedirectResolver.redirectToUrl(failureRedirectUrl);
            } else {
                UrlRedirectResolver.redirectToUrl(successRedirectUrl);
            }
            clearInterval(timer);
        },
        [Actions.BVR__START_REDIRECT_TO_STORE_TIMER](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            const storeRedirectTimeout = context.getters[Getters.BVR__GET_REDIRECT_TIMEOUT_IN_SECONDS];

            const timer = setInterval(() => {
                context.dispatch(Actions.BVR__REDIRECT_TO_STORE, timer)
            }, storeRedirectTimeout * 1000);
        },
        [Actions.BVR__START_PUSH_SEND_SUSPENSION_TIMER](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            const lastPushSendAttemptDate = context.getters[Getters.BVR__GET_LAST_PUSH_SEND_ATTEMPT_DATE];
            const pushSendSuspensionInSeconds = context.getters[Getters.BVR__GET_PUSH_SEND_SUSPENSION_IN_SECONDS];
            const previousTimer = context.getters[Getters.BVR__GET_PUSH_SEND_SUSPENSION_TIMER];
            context.commit(Mutations.BVR__SET_APPROVE_BUTTON_LOCK_STATE, true);

            if (previousTimer) {
                clearInterval(previousTimer);
            }

            const timer = setInterval(() => {
                const timeLeftInSeconds = TimeCalculator.computePaymentTimeLimitInSeconds(
                    lastPushSendAttemptDate,
                    0,
                    pushSendSuspensionInSeconds
                )
                if (timeLeftInSeconds < 1) {
                    context.dispatch(Actions.BVR__STOP_PUSH_SEND_SUSPENSION_TIMER);
                    context.commit(Mutations.BVR__SET_PUSH_SEND_SUSPENSION_TIME_LEFT, 0);
                    context.commit(Mutations.BVR__SET_APPROVE_BUTTON_LOCK_STATE, false);
                    return;
                }
                context.commit(
                    Mutations.BVR__SET_PUSH_SEND_SUSPENSION_TIME_LEFT,
                    timeLeftInSeconds
                );
            }, 1000);
            context.commit(Mutations.BVR__SET_PUSH_SEND_SUSPENSION_TIMER, timer);
        },
        [Actions.BVR__STOP_PUSH_SEND_SUSPENSION_TIMER](context: ActionContext<IBvrCheckoutState, IRootState>): void {
            const timer = context.getters[Getters.BVR__GET_PUSH_SEND_SUSPENSION_TIMER];
            clearInterval(timer);
        }
    }
}