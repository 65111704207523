import { createRouter, createWebHistory } from "vue-router";

const bvrFaviconLink = "/favicon.ico";

const routes = [
  {
    path: "/BvrCheckout/:id",
    name: "BvrPayCheckout",
    component: () => import(/* webpackChunkName: "checkout-page" */ "@/views/BvrPayCheckout/BvrPayCheckout.vue"),
    meta: { faviconLink: bvrFaviconLink },
  },
  {
    path: "/BvrCheckout/error",
    name: "ErrorPage",
    component: () => import(/* webpackChunkName: "error-page" */ "@/views/BvrPayCheckout/BvrCheckoutErrorPage.vue"),
    meta: { faviconLink: bvrFaviconLink },
  },
  {
    path: "/:catchAll(.*)",
    name: "BvrCheckoutPageNotFound",
    component: () => import(/* webpackChunkName: "not-found-page" */ "@/views/BvrPayCheckout/BvrCheckoutPageNotFound.vue"),
    meta: { faviconLink: bvrFaviconLink },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
