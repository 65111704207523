
import { ref } from "vue";
import LandscapeOrientationWarning from "./common-components/LandscapeOrientationWarning.vue";

export default {
  name: "LayoutDefault",
  components: {
    LandscapeOrientationWarning,
  },
  setup() {
    const contentReady = ref(false);

    const setContentReady = () => {
      contentReady.value = true;
    }

    const getContentReadyFuction = () => {
      return setContentReady;
    }

    document.addEventListener("DOMContentLoaded", setContentReady);

    return {
      getContentReadyFuction, // Just for unit-testing
      setContentReady, // Just for unit-testing
      contentReady,
    };
  },
};
