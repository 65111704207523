import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_landscape_orientation_warning = _resolveComponent("landscape-orientation-warning")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return _withDirectives((_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    id: "main-layout"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_landscape_orientation_warning, { ref: "orientation-warning" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 512)), [
    [_vShow, $setup.contentReady]
  ])
}