import { AuthType } from '@/enums/AuthType';

export default class BaseApproveRequest {
    public amount?: number;
    public currency?: string;
    public description?: string;
    public partnerOrderId?: string;
    public partnerName?: string;
    public partnerId?: number;
    public merchantId?: number;
    public authType?: AuthType;
    public successRedirectUrl?: string;
    public failureRedirectUrl?: string;
    public callBackUrl?: string;
    public cultureName?: string;
    public deepLinkUrl?: string;
    public isRepeatRequest?: string;
    public customParameters?: string;

    constructor(data?: BaseApproveRequest) {
        Object.assign(this, data);
    }
}