// Checkout module
export const BVR__GET_ERROR_CODE = "BVR__GET_ERROR_CODE";
export const BVR__GET_IS_CHECKOUT_WAITING = "BVR__GET_IS_CHECKOUT_WAITING";
export const BVR__GET_CHECKOUT_DATA = "BVR__GET_CHECKOUT_DATA";
export const BVR__GET_CURRENT_CHECKOUT_SCREEN = "BVR__GET_CURRENT_CHECKOUT_SCREEN";
export const BVR__GET_PAYMENT_BONUS_AMOUNT = "BVR__GET_PAYMENT_BONUS_AMOUNT";
export const BVR__GET_PAYMENT_AUTHORIZATION_START_DATETIME = "BVR__GET_PAYMENT_AUTHORIZATION_START_DATETIME";
export const BVR__GET_PAYMENT_TIME_LIMIT_IN_SECONDS = "BVR__GET_PAYMENT_TIME_LIMIT_IN_SECONDS";
export const BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT = "BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT";
export const BVR__GET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT = "BVR__GET_PAYMENT_AUTHORIZATION_ATTEMPTS_LIMIT";
export const BVR__GET_PAYMENT_TIMER_VALUE = "BVR__GET_PAYMENT_TIMER_VALUE";
export const BVR__GET_CLIENT_PHONE_INPUT_ERROR_MESSAGE = "BVR__GET_CLIENT_PHONE_INPUT_ERROR_MESSAGE";
export const BVR__GET_IS_CLIENT_PHONE_INPUT_ERROR = "BVR__GET_IS_CLIENT_PHONE_INPUT_ERROR";
export const BVR__GET_CHECKOUT_APPROVE_TIMER = "BVR__GET_CHECKOUT_APPROVE_TIMER";
export const BVR__GET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER = "BVR__GET_CAN_EDIT_CHECKOUT_CLIENT_PHONE_NUMBER";
export const BVR__GET_BONUS_MULTIPLICATION_FACTOR = "BVR__GET_BONUS_MULTIPLICATION_FACTOR";
export const BVR__GET_LAST_PUSH_SEND_ATTEMPT_DATE = "BVR__GET_LAST_PUSH_SEND_ATTEMPT_DATE";
export const BVR__GET_PUSH_SEND_SUSPENSION_IN_SECONDS = "BVR__GET_PUSH_SEND_SUSPENSION_IN_SECONDS";
export const BVR__GET_PUSH_SEND_SUSPENSION_TIME_LEFT = "BVR__GET_PUSH_SEND_SUSPENSION_TIME_LEFT";
export const BVR__GET_PUSH_SEND_SUSPENSION_TIMER = "BVR__GET_PUSH_SEND_SUSPENSION_TIMER";
export const BVR__GET_APPROVE_BUTTON_LOCK_STATE = "BVR__GET_APPROVE_BUTTON_LOCK_STATE";
export const BVR__GET_APPROVE_ERROR = "BVR__GET_APPROVE_ERROR";
export const BVR__GET_REDIRECT_TIMEOUT_IN_SECONDS = "BVR__GET_REDIRECT_TIMEOUT_IN_SECONDS";
export const BVR__GET_IS_PUSH_CONFIRM_REMINDER_VISIBLE = "BVR__GET_IS_PUSH_CONFIRM_REMINDER_VISIBLE";
export const BVR__GET_MERCHANT_INFO = "BVR__GET_MERCHANT_INFO";
export const BVR__GET_LOYALTY_POINTS_AMOUNT = "BVR__GET_LOYALTY_POINTS_AMOUNT";
export const BVR__GET_LOYALTY_POINTS_OFFER = "BVR__GET_LOYALTY_POINTS_OFFER";
export const BVR__GET_IS_LOYALTY_POINTS_LOAD_WAITING = "BVR__GET_IS_LOYALTY_POINTS_LOAD_WAITING";
export const BVR__GET_IS_CHECKOUT_PAYMENT_SUSPEND = "BVR__GET_IS_CHECKOUT_PAYMENT_SUSPEND";
export const BVR__GET_IS_ORDER_NUMBER_HIDDEN = "BVR__GET_IS_ORDER_NUMBER_HIDDEN";